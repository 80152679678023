<div class="wrapper">
    <h2>Privacy Policy</h2>
    <p>
        TNG Technology Consulting GmbH (hereinafter referred to as TNG / We)
        welcomes your visit to our website and your interest in our company. We
        take the protection of your personal data seriously and want you to feel
        safe and comfortable when visiting our website.
    </p>
    <p>
        TNG aims to minimize the use of personal data in the internet services
        for which we are responsible. All personal data accumulated within the
        framework of the internet services provided by TNG will be processed and
        used in accordance with the applicable regulations for the protection of
        personal data. Under no circumstances will TNG use your personal data
        for other purposes.
    </p>
    <h3>1. Controller</h3>
    <p>
        Data processing on this website is the responsibility of the controller,
        namely:
    </p>
    <p>
        TNG Technology Consulting GmbH<br />
        Beta-Straße 13<br />
        85774 Unterföhring<br />
        Germany<br />
    </p>
    <h3>2. Data security</h3>
    <p>
        TNG Technology Consulting GmbH uses technical and organizational
        security measures to protect the data made available to TNG Technology
        Consulting GmbH from accidental or intentional manipulation, loss,
        destruction or access by unauthorized persons.<br />
        Our security measures are continuously updated in line with technological
        developments.
    </p>
    <h3>3. Data Processing</h3>
    <p>
        Here we inform you about how we process your personal data. "Personal
        data" in this context includes all information relating to an identified
        or identifiable natural person. "Processing" means all operations or
        series of operations relating to personal data, such as the collection
        or storage of personal data.
    </p>
    <p>
        Each time you visit our website, we automatically collect and store the
        following data using AWS Amplify from Amazon Web Services, Inc.:
    </p>
    <ul>
        <li>IP address</li>
        <li>Date and time of access</li>
        <li>Information about your browser and operating system</li>
        <li>Websites through which you have been redirected to our website</li>
        <li>Websites that you access through our website</li>
    </ul>
    <p>
        This data processing is based on our legitimate interest to make our
        website accessible to users, to identify any functional problems or
        capacity deficits of our website and to verify security in accordance
        with Art. 6 para. 1 lit. f DS-GVO/UK GDPR.
    </p>
    <p>
        As the Content Delivery Network (CDN) of AWS is being used in order to
        ensure optimal availability of the website, it cannot be ruled out that
        the data held and processed by AWS will be provided from a server
        outside the EU. AWS has committed itself in standard data protection
        clauses according to Art. 46 para. 2 lit c. (DS-GVO, UK GDPR) or Art. 16
        para. 2 lit. d (DSG) to comply with data protection standards also in
        these cases; more detailed information can be found <a
            href="https://aws.amazon.com/service-terms/">here</a
        >.
    </p>
    <p>
        During the creation of a presentation using our website, you can upload
        the following information:
    </p>
    <ul>
        <li>A text prompt describing your presentation (required)</li>
        <li>A photo of yourself (optional)</li>
        <li>A recording of your voice (optional)</li>
    </ul>
    <p>
        These data are stored in AWS S3 during the creation of the presentation
        until you have finished viewing the presentation. We use only AWS zones
        located within the European Union. After viewing the presentation, you
        data are no longer retained.
    </p>
    <p>
        Additionally, your data are sent to the following third-party services:
    </p>
    <ul>
        <li>
            Your text prompt is sent to OpenAI for the generation of
            presentation text (<a
                href="https://openai.com/en-GB/policies/row-privacy-policy/"
                >privacy policy</a
            >)
        </li>
        <li>
            If you have updated a voice recording, it is sent to ElevenLabs to
            generate a voiceover for the presentation (<a
                href="https://elevenlabs.io/privacy-policy">privacy policy</a
            >). This generated voiceover is also sent to D-ID (see following).
        </li>
        <li>
            If you have uploaded a photo, it is sent to D-ID together with the
            generated voiceover to create a presenter video for the presentation
            (<a href="https://www.d-id.com/privacy-policy/">privacy policy</a>)
        </li>
    </ul>
    <p>
        This data processing happens only with your consent in accordance with
        Art. 6 para. 1 lit. a DS-GVO/UK GDPR.
    </p>

    <h3>4. Rights of Data Subjects</h3>
    <h4>4.1 DS-GVO (EU) / Data Protection Act 2018 & UK GDPR (UK)</h4>
    <p>
        You have the right, in accordance with the laws applicable to you and to
        the extent provided therein, to access your personal data and to require
        us to update, rectify or erase your personal data. In addition, in
        accordance with applicable law, you may restrict TNG's processing of
        your personal data, and you have the right to data portability. Your
        right to access your personal data includes the right to obtain a copy
        of all or certain portions of your personal data in our possession, as
        long as the provision of such data by TNG does not adversely affect the
        rights and freedoms of other persons.
    </p>
    <p>
        Right to object: you have the right to object to the processing of your
        personal data solely on the basis of TNG's legitimate interest. If you
        exercise your right to object hereto, TNG will no longer process your
        personal data unless there are compelling legitimate reasons for further
        processing or the processing is necessary to establish, exercise or
        defend legal claims.
    </p>
    <p>
        If the processing is based on your consent, you can revoke this consent
        at any time. This revocation only affects future processing.
    </p>
    <p>
        You also have the right to lodge a complaint with the relevant data
        protection authority if you believe that the processing of your personal
        data has violated applicable data protection laws.
    </p>
    <h4>4.2 Privacy Act (Australia)</h4>
    <p>
        You have the right of access to your data and to request rectification
        of your data. You also have the right to lodge a complaint if you
        believe that the processing of your personal data has violated
        applicable data protection laws. In all such cases, please contact our
        data protection officer (see section 5).
    </p>
    <p>
        If you wish to lodge a complaint about our handling of your personal
        information, you can contact our data protection officer. We take all
        complaints seriously and will investigate your concerns promptly and
        fairly. We will respond to your complaint within a reasonable timeframe,
        usually within 30 days, and inform you of the steps we have taken or
        will take to address your concerns. We may ask you to provide additional
        relevant details and may discuss your complaint with our internal
        departments, our service providers, and others where appropriate. If you
        are not satisfied with our response, you have the right to lodge a
        complaint with your relevant data protection authority, e.g. the Office
        of the Australian Information Commissioner at <a
            href="https://www.oaic.gov.au/about-us/contact-us/"
            >https://www.oaic.gov.au/about-us/contact-us/</a
        >.
    </p>
    <h4>4.3 Privacy Act (New Zealand)</h4>
    <p>
        You have the right of access to your data and to request rectification
        of your data. In such cases, please contact our data protection officer
        (see section 5).
    </p>
    <h3>5. Data Protection Officer</h3>
    <p>
        If you have any questions regarding the processing of your personal
        data, you can contact our data protection officer directly, who is also
        available in case of requests for access, inquiries or complaints:
    </p>
    <p>
        Dr. Marcel Lippmann<br />
        TNG Technology Consulting GmbH<br />
        Beta-Straße 13<br />
        85774 Unterföhring<br />
        Germany<br /><a
            href="mailto:datenschutz@tngtech.com"
            title="Open in email program"
            class="mail">datenschutz(at)tngtech.com</a
        >
    </p>
    <p>
        We can supply our GPG key on request, should you wish to send us your
        request in encrypted form.
    </p>

    <h3>6. Version</h3>
    <p>
        This privacy notice was last modified on September 10th, 2024. We
        reserve the right to amend this declaration at any time to reflect
        changes in our data processing, for example. In the event of changes, we
        will post the new declaration on this website.
    </p>
</div>

<style>
    .wrapper {
        margin-top: 1rem;
        padding: 1rem;
    }
</style>
