<script lang="ts">
    import describeImage from '../assets/description.webp';
    import { createEventDispatcher } from 'svelte';
    import { _ } from 'svelte-i18n';
    import { countDown } from './logic/countDown';
    import { lastValueFrom, tap } from 'rxjs';
    import CircularProgressBar from './CircularProgressBar.svelte';

    export let style: 'presentation' | 'story' | 'darktale' = 'presentation';

    const newRecording = createEventDispatcher<{
        newRecording: { encodedImage: string; encodedAudio: string };
    }>();
    const imageWidth = 1024;

    let audioElement: HTMLAudioElement;
    let videoElement: HTMLVideoElement;
    let canvasElement: HTMLCanvasElement;

    let stream: MediaStream;
    let mediaRecorder: MediaRecorder;
    let audioChunks: BlobPart[] = [];

    let encodedImage: string | null = null;
    let encodedAudio: string | null = null;

    let recordDialogElement: HTMLDialogElement;
    let isRecordingAudio = false;
    let modalContent:
        | 'imageRecording'
        | 'imageApproval'
        | 'audioRecording'
        | 'audioApproval'
        | 'imageRecordingError' = 'imageRecording';

    let countDownProgress:
        | { fractionComplete: number; timeLeft: string }
        | undefined = undefined;

    export let personSelected: boolean = false;

    $: if (videoElement && stream) {
        startWebcam();
    }

    export async function startCapture() {
        stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });

        modalContent = 'imageRecording';
        recordDialogElement.showModal();
    }

    function startWebcam() {
        videoElement.srcObject = stream;
        showCameraImage();
    }

    function showCameraImage() {
        const context = canvasElement.getContext('2d');

        if (!context) {
            throw new Error('Could not get 2d context from canvas');
        }
        const draw = function () {
            try {
                context.drawImage(
                    videoElement,
                    (videoElement.videoWidth - videoElement.videoHeight) / 2,
                    0,
                    videoElement.videoHeight,
                    videoElement.videoHeight,
                    0,
                    0,
                    canvasElement.width,
                    canvasElement.height
                );

                requestAnimationFrame(draw);
            } catch (e) {
                // to be ignored
            }
        };

        requestAnimationFrame(draw);
    }

    function takeSnapshot() {
        (async () => {
            const COUNTDOWN_SECONDS = 3;
            await lastValueFrom(
                countDown(COUNTDOWN_SECONDS).pipe(
                    tap(({ fractionComplete, timeLeftMiliseconds }) => {
                        countDownProgress = {
                            fractionComplete: fractionComplete,
                            timeLeft: (
                                timeLeftMiliseconds / 1000
                            ).toLocaleString('de-DE', {
                                minimumIntegerDigits: 2,
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                            }),
                        };
                    })
                )
            );
            countDownProgress = undefined;

            const blob = await new Promise<Blob>((resolve, reject) => {
                canvasElement.toBlob((blobby) => {
                    if (blobby) {
                        resolve(blobby);
                    } else {
                        reject(new Error('no blob received'));
                    }
                });
            });
            encodedImage = await blobToBase64(blob);
            modalContent = 'imageApproval';
        })().catch((error) => {
            console.error('error taking image', error);
            modalContent = 'imageRecordingError';
        });
    }

    function acceptImage() {
        modalContent = 'audioRecording';
        setupAudioRecording(stream);
    }

    function retakeImage() {
        modalContent = 'imageRecording';
    }

    function setupAudioRecording(stream: MediaStream) {
        let audioTracks = stream.getAudioTracks();
        let audioOnlyStream = new MediaStream(audioTracks);
        mediaRecorder = new MediaRecorder(audioOnlyStream);
        mediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
        };
        mediaRecorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
            encodedAudio = await blobToBase64(audioBlob!!);
            modalContent = 'audioApproval';

            console.log(audioElement);
            audioElement?.play();
            setTimeout(() => {
                audioElement?.pause();
            }, 10);
        };
    }

    function startAudioRecording() {
        audioChunks = [];
        mediaRecorder.start();
        isRecordingAudio = true;
    }

    function stopAudioRecording() {
        mediaRecorder.stop();
        isRecordingAudio = false;
    }

    function acceptAudio() {
        audioElement?.pause();
        recordDialogElement.close();
        stopAllTracks();

        personSelected = true;
        newRecording('newRecording', {
            encodedImage: encodedImage!!,
            encodedAudio: encodedAudio!!,
        });
    }

    function retakeAudio() {
        audioElement?.pause();

        modalContent = 'audioRecording';
    }

    function reset() {
        personSelected = false;
        encodedImage = null;
        encodedAudio = null;
        recordDialogElement.close();
        stopAllTracks();
    }

    function stopAllTracks() {
        stream.getTracks().forEach((track) => track.stop());
    }

    async function blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }
</script>

<dialog bind:this={recordDialogElement} class="modal">
    {#if modalContent === 'imageRecording'}
        <h3>{$_('record.takeSnapshotHeader')}</h3>

        <div class="modal-body">
            <!-- svelte-ignore a11y-media-has-caption since there are simply no captions -->
            <video
                class="video"
                bind:this={videoElement}
                width={imageWidth}
                autoplay
                playsinline
                muted
            />
            <canvas
                class="camera-feed snapshot-image"
                bind:this={canvasElement}
                width={imageWidth}
                height={imageWidth}
            />
            {#if countDownProgress}
                <div class="progress-overlay">
                    <CircularProgressBar
                        fraction={1 - countDownProgress.fractionComplete}
                    >
                        <span class="countdown-timer">
                            {countDownProgress.timeLeft}s
                        </span>
                    </CircularProgressBar>
                </div>
            {/if}
        </div>

        <div class="button-container">
            <slot
                name="recordButton"
                {takeSnapshot}
                {reset}
                snapshotLabel={$_('record.takeSnapshot')}
                resetLabel={$_('record.cancel')}
            >
                <button on:click={takeSnapshot} disabled={!!countDownProgress}>
                    {$_('record.takeSnapshot')}
                </button>
                <button on:click={reset} disabled={!!countDownProgress}>
                    {$_('record.cancel')}
                </button></slot
            >
        </div>
    {:else if modalContent === 'imageApproval'}
        <h3>{$_('record.acceptSnapshotHeader')}</h3>

        <div class="modal-body">
            <img
                class="snapshot-image"
                src={encodedImage}
                alt={$_('record.portrait')}
            />
        </div>
        <div class="button-container">
            <slot
                name="acceptImage"
                {acceptImage}
                {retakeImage}
                {reset}
                acceptImageLabel={$_('record.acceptSnapshot')}
                retakeImageLabel={$_('record.retakeImage')}
                resetLabel={$_('record.cancel')}
            >
                <button on:click={acceptImage}>
                    {$_('record.acceptSnapshot')}
                </button>
                <button on:click={retakeImage}>
                    {$_('record.retakeImage')}
                </button>
                <button on:click={reset}>
                    {$_('record.cancel')}
                </button>
            </slot>
        </div>
    {:else if modalContent === 'audioRecording'}
        <h3>{$_('record.audioRecordingHeader')}</h3>

        <div class="modal-body">
            <p class="read-content-container">
                {$_(
                    style === 'story'
                        ? 'newPresentation.audio.recordContentVariant'
                        : 'newPresentation.audio.recordContent'
                )}
            </p>
            <div class="describe-image-container">
                {#if style === 'story'}
                    <img class="describe-image" src={describeImage} alt="" />
                {/if}
            </div>
        </div>

        <div class="button-container">
            <slot
                name="recordAudio"
                {startAudioRecording}
                {stopAudioRecording}
                {isRecordingAudio}
                {reset}
                startAudioRecordingLabel={$_('record.startAudioRecording')}
                stopAudioRecordingLabel={$_('record.stopAudioRecording')}
                resetLabel={$_('record.cancel')}
            >
                <button
                    on:click={startAudioRecording}
                    disabled={isRecordingAudio}
                >
                    {$_('record.startAudioRecording')}
                </button>
                <button
                    on:click={stopAudioRecording}
                    disabled={!isRecordingAudio}
                    class="recording-animation"
                >
                    {$_('record.stopAudioRecording')}
                </button>
                <button on:click={reset}>
                    {$_('record.cancel')}
                </button>
            </slot>
        </div>
    {:else if modalContent === 'audioApproval'}
        <h3>{$_('record.acceptAudioHeader')}</h3>

        <div class="modal-body">
            <audio
                class="audio"
                bind:this={audioElement}
                controls
                preload="auto"
                src={encodedAudio}
            />
        </div>

        <div class="button-container">
            <slot
                name="acceptAudio"
                {acceptAudio}
                {retakeAudio}
                {reset}
                acceptAudioLabel={$_('record.acceptAudioRecording')}
                retakeAudioLabel={$_('record.retakeAudioRecording')}
                resetLabel={$_('record.cancel')}
            >
                <button on:click={acceptAudio}>
                    {$_('record.acceptAudioRecording')}
                </button>
                <button on:click={retakeAudio}>
                    {$_('record.retakeAudioRecording')}
                </button>
                <button on:click={reset}>
                    {$_('record.cancel')}
                </button>
            </slot>
        </div>
    {:else if modalContent === 'imageRecordingError'}
        Error
    {/if}
</dialog>

<style>
    .modal {
        width: 70vh;
        height: 70vh;
        background-color: white;
        border: 0.2rem solid var(--bs-light);
        padding: 2vh;
        border-radius: 0.5rem;
        box-shadow: 0 0 0.5rem 0.5rem rgba(255, 255, 255, 0.1);
        overflow: hidden;
        flex-direction: column;
    }

    .modal[open] {
        display: flex;
    }

    .modal-body {
        max-height: 90%;
        /* center everything */
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 1.5svh;
        overflow: clip;
        position: relative;
    }

    .read-content-container {
        height: min-content;
        flex-basis: min-content;
        flex-shrink: 0;
        flex-grow: 0;
        padding: 0;
    }

    .describe-image-container {
        width: 100%;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .describe-image {
        object-fit: contain;
        height: 100%;
        aspect-ratio: 1 / 1;
    }

    .modal::backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 400px) {
        .modal {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0;
            border: none;
        }
    }

    .video {
        width: 0;
        height: 0;
    }

    .snapshot-image {
        width: auto;
        height: 100%;
        transform: scaleX(-1);
    }

    .camera-feed {
        width: auto;
        height: 100%;
    }

    .audio {
        margin-top: 40%;
    }

    .recording-animation {
        animation: pulse 1s infinite;
    }

    .button-container {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
    }

    .progress-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .countdown-timer {
        color: white;
        font-variant-numeric: tabular-nums;
    }
</style>
