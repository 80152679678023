import {
    endWith,
    first,
    interval,
    map,
    Observable,
    startWith,
    takeUntil,
} from 'rxjs';

const UPDATE_INTERVAL_MS = 97;

export function countDown(
    fromSeconds: number
): Observable<{ fractionComplete: number; timeLeftMiliseconds: number }> {
    const MAX_TIME_MS = fromSeconds * 1000;
    return interval(UPDATE_INTERVAL_MS).pipe(
        map((i) => (i + 1) * UPDATE_INTERVAL_MS),
        startWith(0),
        takeUntil(interval(MAX_TIME_MS).pipe(first())),
        map((time) => ({
            fractionComplete: time / MAX_TIME_MS,
            timeLeftMiliseconds: MAX_TIME_MS - time,
        })),
        endWith({
            fractionComplete: 1,
            timeLeftMiliseconds: 0,
        })
    );
}
