<script lang="ts">
    export let fraction: number;
    export let color: string = 'blue';
    export let backgroundOpacity: number = 0.5;

    let percent: number;
    $: percent = Math.floor(fraction * 100);
</script>

<div
    class="progress-container"
    style="--percentage: {(fraction * 100).toFixed(
        2
    )}%; --background-opacity: {backgroundOpacity}; --background-color: {color};"
>
    <div
        role="progressbar"
        aria-valuenow={percent}
        aria-valuemin={0}
        aria-valuemax={100}
        class="progress-circle"
    ></div>
    <span class="label">
        <slot>{percent}%</slot>
    </span>
</div>

<style>
    .progress-container {
        height: 100%;
        width: auto;
        aspect-ratio: 1 / 1;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
    }

    .label {
        z-index: 1;
    }

    .progress-circle {
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;

        background: conic-gradient(
            var(--background-color) calc(var(--percentage) - 0.05%),
            transparent calc(var(--percentage) + 0.05%),
            transparent 0
        );
        opacity: var(--background-opacity);
    }
</style>
