import type { LocalizationSettings } from './types';
import { PresentationTone } from '../../types';

const en: LocalizationSettings = {
    key: 'en',
    languageLabel: 'English',
    languageNameForGeneration: 'English',
    switchLabel: 'Language settings',
    rotatePrompt: 'Please turn your device to view the presentation',
    overview: {
        title: 'AI Slides',
        tagline: 'Let Artificial Intelligence create your presentations!',
        newPresentation: 'Start creating',
    },
    newPresentation: {
        createPresentationTitle: 'Define the topic of your presentation.',
        presentationTitle: 'Think of a topic / title for your presentation',
        toneErnest: 'serious',
        toneFunny: 'fun',
        createButton: 'Use prompt & continue',
        presentationStyle: 'Presentation style',
        topic: 'about ‘{topic}’',
        backToLastPresentation: 'Go back to the presentations',
        backToOverview: 'Go back to the overview',
        presenterTitle: 'Presenter',
        clearPresenter: 'Remove',
        chooseMode: 'Please choose.',
        chooseModeInfoMessage:
            'Choose your preferred mode. You can always switch back to simple mode in the process. Try it now!',
        simpleMode: {
            title: 'Simple Mode',
            description:
                'Continue with your prompt to generate the presentation with a general voice and look.',
        },
        advancedMode: {
            title: 'Advanced Mode',
            description: 'Personalize your presentation with generative AI.',
        },
        dataPrivacy: {
            title: 'Information about Data Privacy.',
            text:
                'We process images, sounds, and other content in the EU and the US with strict security measures, including encryption and access controls, to ensure your data is kept secure and not used for training purposes.\n\n' +
                'Generated content is deleted immediately after generation and once you leave presentation mode.\n\n' +
                'Enjoy the latest technologies{location} while we prioritize your security and comply with all relevant data protection laws.\n\n' +
                'For further information, please visit {privacyUrl}.',
            simple: 'Simple Mode',
            accept: 'Accept & continue',
            futureLab: ' in our Future Lab',
        },
        photo: {
            takeTitle: 'Take a photo.',
            takeButton: 'Start 3 second timer',
            takeError:
                'The picture could not be taken due to a technical error.',
            goToSimple: 'Create a simple presentation instead',
            takeInfoDialog:
                'Your picture is used to generate an avatar based on your look. The avatar will be the moderator of your presentation.',
            verifyTitle: 'Photo ok?',
            retake: 'Take new picture',
            accept: 'Accept & continue',
        },
        audio: {
            recordTitle: 'Record your audio.',
            recordInfo:
                'Your recording of the text below is used to generate the voice over of the presentation. We suggest to talk clearly in an engaging way to achieve the best result.',
            recordContent:
                'Early in the morning, the city awakens to the sound of busy streets and cheerful birds. People hurry to work, drink coffee, and greet each other with a smile. In the park, dogs run freely, chasing their favorite toys. The air is fresh, filled with the scent of blooming flowers and freshly baked bread from the nearby bakery. Life in the city pulses, and every corner offers a new story to discover.',
            recordContentVariant:
                'The little lamb Wooly hops happily across the meadow. Wooly keeps sniffing the flowers and herbs. She finds sunflowers particularly beautiful. Wooly would also like to nibble on one of the sunflowers, but unfortunately, she is much too small. She wishes she could fly like the butterflies or the bees. Then she could finally try a sunflower. How would it taste? Wooly is lost in thought and doesn\'t notice that all the other sheep have long since moved on. Then she hears her mother\'s bleating and runs to her as fast as she can. "Were you daydreaming again?" her mother asks. "Yes," Wooly replies, "about sunflowers." "That suits your sunny disposition," says her mother.',
            startRecording: 'Start recording',
            finishRecording: 'Finish recording',
            infoDialog:
                'Please read the text on the screen aloud. It will be used to generate the voiceover of your presentation. Start when you’re ready.',
            error: 'The audio recording could not be saved due to a technical error.',
            verifyTitle: 'Recording finished.',
            verifyInfo:
                'You can choose to record a new audio if you e.g. accidentally stopped the audio too early. Don’t worry about it too much. ',
            verifyContent:
                'Pauses or other small interferences in the audio do not affect the result. However, if there are major background noises you can record a new audio to achieve the best possible outcome.',
            retake: 'Record new audio',
            accept: 'Accept & continue',
        },
        generating: {
            loading: 'Loading your presentation',
            learnMore: "Learn more while you're waiting",
            infoDialog:
                'While we are generating your presentation, learn more about this showcase by navigating through the screens.',
        },
        cancel: {
            title: 'Cancel advanced process?',
            backHome: 'Back to home',
            simple: 'Switch to simple',
            continue: 'Continue advanced',
            infoDialog:
                'Be careful! If you switch to simple mode or go back to home, your progress in the advanced mode will be lost.',
        },
    },
    newStory: {
        createStory: 'Which story should I tell?',
        storyReady: 'Done! Would you like me to read you the story?',
        creationFailed: 'Oops, something went wrong.',
        titleLabel: 'Your title',
        submitButtonText: "Let's go",
        readOutButtonText: 'Read out',
        retryButtonText: 'Try again',
    },
    newDallicParty: {
        initialPrompt: 'Initial prompt',
        changePrompt: 'Change prompt',
        numberOfIterations: 'Number of iterations',
    },
    presentationReady: {
        goToPresentation: 'Start watching',
        title: 'Your presentation is ready!',
        infoDialog: 'Watch your presentation on the screen above.',
    },
    generationFailed: {
        headline: 'Technical error.',
        timeoutHeadline: 'Loading timeout.',
        spicyHeadline:
            'The given topic was detected to be potentially inappropriate',
        retryPrompt: 'Try again',
        restartPrompt: 'Start with prompt',
        quotaExceeded: 'The limit for the number of presentations was reached',
        info: 'A technical error occurred. This can happen e.g. when the AI systems are not available. Please just try again.',
        timeoutInfo:
            'Unfortunately, your presentation reached the maximum loading time of 5 minutes. This can be due to the server being overutilized.',
        spicyInfo:
            'We automatically moderate your prompt. This is necessary to comply with the terms of use of the AI services.',
        quotaExceededInfo:
            'Please inform a staff member that the limit for the number of presentations was reached.',
    },
    presentation: {
        jumpToOverview: 'Back to overview',
        jumpToNext: 'Jump to the next presentation',
        createYourOwn: 'Create your own presentation',
        confirmTitle: 'Presentation completed',
        confirmNext: 'To the next presentation',
        replay: 'Play again',
        confirmCreateOwn: 'Do you want to create your own presentation?',
        confirmCreateOwnAction: 'Create my own presentation',
        declineCreateOwnAction: 'Continue viewing this presentation',
        title: 'Presentation is playing',
        cancelPrompt: 'Do you really want to cancel?',
        finished: 'Presentation finished!',
        answerQuestionFailed: 'The answer could not be generated',
        actions: {
            cancel: 'Cancel presentation',
            continue: 'Continue watching',
            pause: 'Pause',
            play: 'Play',
            toOverview: 'Back to start',
            replay: 'Watch again',
            toggleSubtitlesOn: 'Turn on subtitles',
            toggleSubtitlesOff: 'Turn off subtitles',
            askQuestion: 'Press and hold to ask a question',
        },
    },
    story: {
        childBookTitle: 'The automatic story teller',
        darkTalesTitle: 'Dark Tales',
        christmasTitle: 'The mechanical Santa',
        hearAgainButtonText: 'Read once again',
        backButtonText: 'Back to the overview',
        playButtonText: 'Play',
    },
    info: {
        aiSlides: {
            title: 'About AI Slides',
            text: `
AI-Slides is an AI-based tool that *transforms a user’s input into a complete presentation*. This process involves multiple AI technologies working together. 

In summary, the process of creating dynamic presentations with advanced AI tools involves the collaboration of GPT-4, ElevenLabs, D-ID, and Dalle-3. From *generating captivating headlines and presenter's text to animating still photos and generating visually striking images*, these advanced AI tools work seamlessly together to create engaging and immersive presentations.

Once everything is done ‒ the audio tracks, the images, and the slide text ‒ they are all integrated together to *form a finished, AI-composed presentation*. This can then be viewed in a web browser, offering a user-friendly way to quickly generate presentations with just a prompt.
            `,
        },
        gpt: {
            title: 'GPT-4',
            text: `
GPT-4, shorthand for Generative Pretrained Transformer 4, is an artificial intelligence system that’s designed to create text. This AI uses a network of algorithms, similar to how the human brain uses a network of neurons, to evaluate, understand, and generate text based on a given prompt. It does this by finding patterns and relationships between words, phrases, and sentences in a massive database of diverse internet text it was trained on.

GPT-4 generates responses to a prompt by using what it has learned to guess the next word in a sentence. This is similar to how we humans might play a game where you have to guess the next word in a sentence. It predicts what comes next by calculating the probability of which word often comes after the last word in the prompt or sentence.
            `,
        },
        dalle: {
            title: 'DALLE-3',
            text: `
In parallel, the **image descriptions provided by GPT-4 are utilized as instructions** for Dalle-3, an **AI-based image generator.** Dalle-3 interprets these instructions to generate appropriate and visually striking images for each slide of the presentation. 

The AI starts with random dots and shapes and slowly changes them until it makes a clear picture that matches the description you gave. This is called **"diffusion modeling."** Dalle-3 shows how well modern AI can understand the meaning of words and turn them into realistic pictures. It can be used for things like making designs, creating art, and generating images with computers or in our case, to create high-quality and contextually relevant images that perfectly complement the content of the presentation. 
            `,
        },
        did: {
            title: 'D-ID',
            text: `
To add a visual storytelling element to the presentation, we introduce D-ID, an AI system that utilizes advanced facial animation techniques to **animate the person's face in the photo**, making it appear as though they are speaking. D-ID requires two essential components:

 - **Photo of a Person's Face:** Provide a still photo featuring the individual whose face you want to animate.
 - **Audio Recording of Speech:** Record the presenter's speech or provide a pre-recorded audio track.
 
D-ID generates a realistic video from the photo speaking the words from the audio. Applications range from fields like game character animation to virtual assistants.
            `,
        },
        elevenlabs: {
            title: 'ElevenLabs',
            text: `
ElevenLabs is an artificial intelligence system that can **generate human-like speech from written text**. It was trained on many samples of real human voices to learn the patterns and characteristics of speech. There are two ways to use ElevenLabs:

 - **Select a pre-made voice** to generate speech that sounds like that voice speaking the provided text.
 - **Provide your own voice sample** to create a "voice clone" that can then generate speech that mimics your own voice. When a voice clone is used, the artificial speech is remarkably similar to the original human voice sample. ElevenLabs shows how AI can be used to realistically recreate individual human voices.
            `,
        },
    },
    record: {
        startRecording: 'Start recording',
        removeRecording: 'Remove recording',
        portrait: 'Portrait image',
        takeSnapshotHeader: 'Take a snapshot',
        takeSnapshot: 'Take snapshot',
        acceptSnapshotHeader: 'Accept snapshot',
        acceptSnapshot: 'Accept snapshot',
        retakeImage: 'Retake image',
        audioRecordingHeader: 'Record audio',
        readText:
            'Early in the morning, the city wakes up to the sound of busy streets and cheerful birds. People rush to their work, sipping coffee and greeting each other with a smile. In the park, dogs run freely, chasing after their favorite toys. The air is fresh, filled with the aroma of blooming flowers and freshly baked bread from the nearby bakery. Life in the city is vibrant, with every corner offering a new story to discover.',
        startAudioRecording: 'Start audio recording',
        stopAudioRecording: 'Stop audio recording',
        acceptAudioHeader: 'Accept audio recording',
        acceptAudioRecording: 'Accept audio recording',
        retakeAudioRecording: 'Retake audio recording',
        cancel: 'Cancel',
    },
    upload: {
        dragDropImage: 'Drag and drop an image here, or click to select',
        dragDropAudio: 'Drag and drop an audio file here, or click to select',
        altImage: 'Portrait',
        audioNotSupported: 'Your browser does not support audio',
    },
    readInfo: 'Read',
    pauseReading: 'Pause',
    generatorMessages: {
        [PresentationTone.FUNNY]: {
            initial: 'Firing up the AI brain for some slide deck magic.',
            messages: [
                "Feeding data into the AI, let's see what it can cook up.",
                'Pulling topics out of the virtual hat for your slide deck.',
                'Judging the relevance of topics, not all ideas are gold.',
                "Flexing the AI's vocabulary muscles for text generation.",
                'Crafting your slide deck outline, like a fine artisan.',
                'Playing around with colors, every slide deck needs a little splash.',
                "First draft done - not perfect, but it's a start.",
                'Turning each slide into a mini masterpiece.',
                'Flipping through the dictionary for the perfect words.',
                'Summoning the power of Picasso for some image placing.',
                'Unleashing the grammar police, courtesy of deep learning.',
                'Running a text-to-common-sense test, keeping it real.',
                'Curating slide order for a jaw-dropping narrative.',
                'Fixing visuals like a pro - clarity is king.',
                'Creating infographics, making numbers fun again.',
                'Bridging slides with smooth transitions, just like a pro skater.',
                'Thinking up a catchy intro, first impressions matter.',
                'Giving the text a readability makeover, out with the complex!',
                "Weaving in audience attention-grabbers, because who doesn't love interaction?",
                'Optimizing slide deck for big screens, small screens, and screens in-between.',
                'Polishing the outline, because we’re all about that shape.',
                'Spinning words into magic, because detail is key.',
                'Jazzing up the text design, legibility never looked so good.',
                'Designing closing slide, the grand finale.',
                'Calling in the virtual inspector gadget for a final visual check.',
                'Playing Tetris with text and visuals, until it fits just right.',
                'Bringing in insightful nuggets, straight from the AI oven.',
                "Scanning sentiment, ensuring the slide deck doesn't hurt feelings.",
                'Making sure our slides are on the same page.',
                'Turning the visual appeal knob up to 11.',
                'Locking in the color scheme, because who decides on the first try?',
                'Double-checking image resolutions, no room for pixel play.',
                'Invoking the power of language processing, hello smart text.',
                'Cooking up a slide deck title that would make Spielberg jealous.',
                'Checking infographic legibility, making sure even grandpa can read it.',
                'Shuffling text sizes and fonts, for the love of style.',
                'Breathing context into images, every picture tells a story.',
                'Timing slides to perfection, not too fast, not too slow.',
                'Trimming the slide deck size, keeping it travel-friendly.',
                'Sit back, final review in progress, almost there...',
                'Testing slide deck structure for a smooth narrative flow.',
                'One last sprinkling of design magic.',
                'Performing last minute detective work for coherence, no mystery unsolved.',
                'Polishing the slide deck for the grand reveal.',
                'Distilling the essence of the slide deck into a killer summary.',
                'Crossing the finish line on design and content.',
                'Exporting your sparkly slide deck, in all its glory.',
                'Tada! Your slide deck is ready for its standing ovation.',
                'Putting a bow on the AI’s work, slide deck check.',
                'Who’s up for a victory dance? Slide deck creation, check.',
                'Shutting down the AI engine, it’s earned a good sleep.',
            ],
        },
        [PresentationTone.ERNEST]: {
            initial: 'Initialising AI system for slide deck creation.',
            messages: [
                'Preprocessing raw data for insights generation.',
                'Creating a list of potential topics for the slide deck.',
                'Validating the relevance of the generated topics.',
                'Initiating generation of contextual text for each topic.',
                'Creating a slide deck outline based on topic analysis.',
                'Designing color scheme for the slide deck.',
                'Creating the first draft of the slide deck design.',
                'Initiating the design of individual slides.',
                'Generating text for individual slides.',
                'Incorporating images and visual elements into slides.',
                'Engaging deep learning algorithms for proofreading text.',
                'Conducting a coherence check on the generated text.',
                'Arranging slides based on the information hierarchy.',
                'Fine-tuning visual elements for optimal clarity.',
                'Generating infographic elements for data representation.',
                'Establishing links between slides for smooth transitions.',
                'Initiating the introduction slide creation.',
                'Enhancing the readability of the slide deck text.',
                'Incorporating audience engagement elements.',
                'Optimizing slide deck for mobile and desktop viewing.',
                'Fine-tuning the outline of the slide deck.',
                'Generating grammatically correct and insightful sentences.',
                'Refining the design of the text for better visibility.',
                'Creating a professional and aesthetically pleasing closing slide.',
                'Running a final check on all visual elements in the slide deck.',
                'Ensuring text and visuals are well-aligned in each slide.',
                'Incorporating AI generated insights into relevant slides.',
                'Deploying machine learning for sentiment analysis of text.',
                'Inspecting the consistency between slides.',
                'Boosting the visual appeal of the slide deck.',
                'Finalizing the color scheme of the slide deck.',
                'Ensuring resolution of all images is optimal.',
                'Deploying natural language processing to enhance the textual content.',
                'Creating a compelling slide deck title.',
                'Inspecting the readability of infographic elements.',
                'Modifying text size and fonts for better legibility.',
                'Adding context to images and visual elements.',
                'Ensuring all slides meet the designated presentation time.',
                'Optimizing overall slide deck size for easy sharing.',
                'Conducting a final review for potential improvements.',
                'Analyzing the structure of the slide deck for logic flow.',
                'Finalizing the content and design of the slide deck.',
                'Running the last coherence check on the slide deck.',
                'Polishing the slide deck for final delivery.',
                'Creating a summary of the slide deck content.',
                'Completing the design and content creation process.',
                'Exporting the finished slide deck in the preferred format.',
                'Slide deck is ready for review and feedback.',
                'AI system is finalizing the slide deck.',
                'The AI-based slide deck creation is complete.',
                'Initiating the deactivation of the slide deck creation algorithm.',
                'AI system shut down after successful creation of slide deck.',
            ],
        },
    },
    keyboardLayout: {
        default: [
            '1 2 3 4 5 6 7 8 9 0 - = {bksp}',
            'q w e r t y u i o p [ ] !',
            'a s d f g h j k l ; ?',
            '{shift} z x c v b n m , . / {shift}',
            '@ {space}',
        ],
        shift: [
            '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
            'Q W E R T Y U I O P { } !',
            'A S D F G H J K L : ?',
            '{shift} Z X C V B N M < > ? {shift}',
            '@ {space}',
        ],
    },
    keyboardLabels: {
        '{bksp}': '⌫',
        '{shift}': '⇧ shift',
        '{space}': 'space',
    },
    landingpage: {
        login: {
            login: 'Login',
            logout: 'Logout',
            username: 'Username',
            password: 'Password',
            changePassword: 'Change Password',
            newPassword: 'New Password',
            repeatNewPassword: 'Repeat',
            close: 'Close',
            saveNewPassword: 'Save new password',
        },
        menu: {
            home: 'Home',
            suggestTopic: 'Suggest a Topic',
            reviewSuggestions: 'Review Suggestions',
            newPresentation: 'New Presentation',
            newStory: 'New Children’s Book',
            newDarkTale: 'New Dark Tale',
            newChristmasTale: 'New Christmas Story',
            newDallicParty: 'New Dallic Party',
        },
        footer: {
            imprint: 'Imprint',
            privacy: 'Privacy Statement',
            language: 'Change language',
        },
        presentations: {
            favorite: 'favorite',
            unfavorite: 'remove from favorites',
            delete: 'delete',
            unpublish: 'unpublish',
            publish: 'Publish',
            failedToDelete: 'failed to delete presentation',
            failedToUnpublish: 'failed to unpublish presentation',
        },
        suggestTopic: {
            title: 'Suggest a presentation topic',
            yourSuggestion: 'your suggestion',
            submitSuggestion: 'submit suggestion',
            suggestionSubmitted: 'Your suggestion has been submitted',
            suggestAnother: 'make another suggestion',
            submissionFailed: 'Sorry, we could not save your submission',
            retry: 'try again',
        },
        manageSuggestions: {
            title: 'Manage suggestions',
            delete: 'delete suggestion',
        },
        newPresentation: {
            title: 'New Presentation',
            topic: 'Topic',
            generateTopic: 'Generate topic',
            useSuggestion: 'Use a suggestion',
            numberOfSlides: 'Number of slides',
            narrationStyle: 'Narration style',
            personality: 'Personality',
            customPersonality: 'Custom Personality',
            yourPersonality: 'You',
            showPersonData: 'Show Person Data',
            hidePersonData: 'Hide Person Data',
            person: {
                name: 'Name',
                description: 'Person Description',
                speaker: 'Speaker',
                imageDescription: 'Image Description',
                fillBlanks: 'Fill in blanks',
                clear: 'Clear Person',
                recording: 'Recording',
                noRecording: 'No recordning yet',
            },
            imageGenerator: 'Image Generator',
            videoGenerator: 'Video Generator',
            textGenerator: 'Text Generator',
            coverSlideVideo: 'Cover Slide Video',
            createCoverSlideVideo: 'Create cover slide video',
            subtitles: 'Subtitles',
            createSubtitles: 'Create subtitles',
            content: 'Content',
            moderateContent: 'Moderate content',
            promotion: 'Promotion',
            noPromotion: 'None',
            createPresentation: 'Create Presentation',
        },
    },
    kiosk: {
        dismissInfoButton: 'Ok, got it',
        overviewInfoMessage:
            'Watch an example presentation or start to create your own presentation.',
        createPresentationInfoMessage:
            'Start by typing a prompt. This will be both the topic and the title of your presentation.',
        languageChoice: 'Please choose your preferred language.',
        impress: {
            title: 'About this showcase',
            infoText:
                'AI Slides is a program from TNG Technology Consulting GmbH. It uses artificial intelligence to create an entire presentation from a user input. Many different AI technologies work together for this purpose.\n' +
                '\n' +
                'It was originally an exhibit that was exhibited at the Deutsches Museum in Bonn. For the FutureLab, the program was adapted and further developed in cooperation between TNG and FG-160.',
            contact: {
                title: 'Get in contact with the experts',
                internal: 'Internal',
                external: 'External',
            },
            footer: 'The Future Lab is a digital innovation project',
        },
        infoFooter:
            'AI Slides was developed by TNG Technology Consulting GmbH in cooperation with BMW',
        qna: {
            title: 'Ask a question about the presentation',
            startRecording: 'Start recording',
            stopRecording: 'Stop recording',
            error: 'Sorry, the question cannot be answered',
        },
    },
};
export default en;
